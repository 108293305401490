/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import find from 'lodash/fp/find.js';
import filter from 'lodash/fp/filter.js';
import get from 'lodash/fp/get.js';
import getOr from 'lodash/fp/getOr.js';
import groupBy from 'lodash/fp/groupBy.js';
import includes from 'lodash/fp/includes.js';
import isEmpty from 'lodash/fp/isEmpty.js';
import last from 'lodash/fp/last.js';
import max from 'lodash/fp/max.js';
import maxBy from 'lodash/fp/maxBy.js';
import orderBy from 'lodash/fp/orderBy.js';
import pipe from 'lodash/fp/pipe.js';
import sortedUniq from 'lodash/fp/sortedUniq.js';
import toLower from 'lodash/fp/toLower.js';
import uniq from 'lodash/fp/uniq.js';
import uniqBy from 'lodash/fp/uniqBy.js';
import values from 'lodash/fp/values.js';
import {
  map, reduce, keys, mapObjIndexed, sort,
} from 'ramda';
import moment from 'moment';
import { createSelector } from 'reselect';
import { edpsByKey } from '@hbrisk/sp3-risk-model-support/edps/edps.js';
import { SUCCESS } from '@hbrisk/sp3-risk-model-support/uploadable/statuses.js';
import { isTerminal } from '@hbrisk/sp3-risk-model-support/uploadable/statusHelpers.js';
import { getDownloads } from '@hbrisk/sp3-risk-model-support/downloads/downloads.js';
import getUniquePeriods from '@hbrisk/sp3-risk-model-support/models/getUniquePeriods.js';
import getUniqueReturnPeriods from '@hbrisk/sp3-risk-model-support/models/getUniqueReturnPeriods.js';
import getLocationLabel from '#support/models/locationHelpers/getLocationLabel.js';
import { ceilCoordinateValue } from '#support/utility/mathFunctions.js';
import authorAbbr from '#support/utility/string/authorAbbreviation.js';

const selectModelId = (_, props) => props.modelId;
const selectName = (_, props) => props.name;
const selectNames = (_, props) => props.names;
const selectResponseId = (_, props) => props.responseId;
const selectEdpKey = (_, props) => props.edpKey;
const selectMethod = (_, props) => props.method;
const selectUpload = (_, props) => props.upload;
const selectDirection = (_, props) => props.direction;

const selectModelFilter = (_, props) => props.modelName;
const selectProjectFilter = (_, props) => props.projectName;
const selectCreatedStartFilter = (_, props) => props.createdStart;
const selectCreatedEndFilter = (_, props) => props.createdEnd;
const selectUpdatedStartFilter = (_, props) => props.updatedStart;
const selectUpdatedEndFilter = (_, props) => props.updatedEnd;
const selectModelLevelFilter = (_, props) => props.modelLevel;
const selectAuthorFilter = (_, props) => props.author;
const selectIncludeArchived = (_, props) => props.includeArchived;
const selectComponentUuid = (_, props) => props.componentUuid;
const selectPercentile = (_, props) => props.percentile;

export const modelsById = (state) => state.entities.models.byId;

const selectModelsMetaById = (state) => state.entities.models.meta.byId;

export const selectModelsMetaAll = (state) => state.entities.models.meta.all;

export const currentModelId = (state) => state.entities.models.current;

export const selectModels = createSelector(
  modelsById,
  (byId) => Object.values(byId),
);

export const modelsUniqueProjects = createSelector(
  selectModels,
  (modelsArr) => pipe(
    filter((m) => !m.archived),
    map((m) => m.projectName || ''),
    (projectArr) => projectArr.filter((x) => x !== ''),
    uniq,
    sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
  )(modelsArr),
);

const emptyOrCaseInsensitiveIncludes = (filterParam, field) => isEmpty(filterParam)
  || (
    pipe(
      toLower,
      includes(toLower(filterParam)),
    )(field)
  );

export const modelsFiltered = createSelector(
  selectModels,
  selectModelsMetaById,
  selectModelFilter,
  selectProjectFilter,
  selectCreatedStartFilter,
  selectCreatedEndFilter,
  selectUpdatedStartFilter,
  selectUpdatedEndFilter,
  selectModelLevelFilter,
  selectAuthorFilter,
  selectIncludeArchived,
  (
    modelsArr,
    metaById,
    modelName,
    projectName,
    createdStartStr,
    createdEndStr,
    updatedStartStr,
    updatedEndStr,
    modelLevel,
    author,
    includeArchived
  ) => (
    modelsArr
      .filter((m) => {
        const authorFirst = getOr('', 'createdBy.firstName', m);
        const authorLast = getOr('', 'createdBy.lastName', m);
        const modelAuthor = `${authorFirst} ${authorLast}`;
        const modelCreatedDateTime = moment(get('createdAt', m));
        const modelUpdatedDateTime = moment(get('updatedAt', m));
        const createdStart = moment(createdStartStr);
        const createdEnd = moment(createdEndStr).endOf('day');
        const updatedStart = moment(updatedStartStr);
        const updatedEnd = moment(updatedEndStr).endOf('day');
        const modelModelType = get('type', m);
        const modelHasRecoveryTime = get('hasRecoveryTime', m);
        return (
          emptyOrCaseInsensitiveIncludes(modelName, get('modelName', m))
          && emptyOrCaseInsensitiveIncludes(projectName, get('projectName', m))
          && (
            emptyOrCaseInsensitiveIncludes(author, modelAuthor, m)
            || emptyOrCaseInsensitiveIncludes(author, authorAbbr(authorFirst, authorLast), m)
          )
          && (includeArchived || !m.archived)
          && (
            ['all', undefined].includes(modelLevel)
            || (modelLevel === 'std' && modelModelType === 1 && !modelHasRecoveryTime)
            || (modelLevel === 'std-rt' && modelModelType === 1 && modelHasRecoveryTime)
            || (modelLevel === 'adv' && modelModelType === 2 && !modelHasRecoveryTime)
            || (modelLevel === 'adv-rt' && modelModelType === 2 && modelHasRecoveryTime)
          )
          && (isEmpty(createdStartStr) || modelCreatedDateTime.isSameOrAfter(createdStart))
          && (isEmpty(createdEndStr) || modelCreatedDateTime.isSameOrBefore(createdEnd))
          && (isEmpty(updatedStartStr) || modelUpdatedDateTime.isSameOrAfter(updatedStart))
          && (isEmpty(updatedEndStr) || modelUpdatedDateTime.isSameOrBefore(updatedEnd))
        );
      })
      .map((model) => ({
        ...model,
        togglingArchive: getOr(false, `${model.uuid}.togglingArchive`, metaById),
      }))
  )
);

export const modelById = createSelector(
  modelsById,
  selectModelId,
  (byId, id) => byId[id]
);

const selectModelMetaById = createSelector(
  selectModelsMetaById,
  selectModelId,
  (meta, id) => meta[id]
);

export const selectFetchingModels = createSelector(
  selectModelsMetaAll,
  (all) => {
    const { validFetchRequests } = all;
    if (validFetchRequests) {
      return Object.keys(validFetchRequests).length > 0;
    }
    return false;
  }
);

export const selectModelIsFetching = createSelector(
  selectModelMetaById,
  (modelMeta) => !isEmpty(getOr({}, 'validFetchRequests', modelMeta)),
);

export const selectModelIsUpdating = createSelector(
  selectModelMetaById,
  (modelMeta) => !isEmpty(getOr({}, 'updateRequests', modelMeta)),
);

export const selectModelIsCopying = createSelector(
  selectModelMetaById,
  (modelMeta) => getOr(false, 'copying', modelMeta),
);

export const selectModelIsTogglingArchive = createSelector(
  selectModelMetaById,
  (modelMeta) => getOr(false, 'togglingArchive', modelMeta),
);

export const selectModelPollingId = createSelector(
  selectModelMetaById,
  (modelMeta) => get('pollingId', modelMeta),
);

export const selectModelFormInputs = createSelector(
  modelsById,
  selectModelId,
  (byId, id) => get(`${id}.formInputs`, byId)
);

export const selectModelHasFormInputs = createSelector(
  selectModelFormInputs,
  (formInputs) => !!formInputs
);

export const selectModelFormInputValue = createSelector(
  modelsById,
  selectModelId,
  selectName,
  (byId, id, field) => get(`${id}.formInputs.${field}.value`, byId)
);

const reduceFormInputs = (field, obj) => reduce((acc, curr) => {
  const prop = get(`${curr}.${field}`, obj);
  return prop ? { ...acc, [curr]: prop } : acc;
}, {}, keys(obj));

export const selectModelHasRun = createSelector(
  modelsById,
  selectModelId,
  (byId, id) => !!get(`[${id}].currentRun`, byId),
);

export const getModelDownloadsData = createSelector(
  modelsById,
  selectModelId,
  (byId, id) => {
    const downloads = getOr([], `${id}.currentRun.downloads`, byId);
    return getDownloads(id, downloads);
  }
);

export const selectModelRunCreatedAt = createSelector(
  modelsById,
  selectModelId,
  (byId, id) => get(`${id}.currentRun.createdAt`, byId),
);

export const selectModelOutputData = createSelector(
  modelsById,
  selectModelId,
  (byId, id) => get(`${id}.currentRun.outputData`, byId),
);

export const selectModelHasOutputData = createSelector(
  selectModelOutputData,
  (data) => !!data,
);

export const selectAutofilledValues = (fieldsByName) => createSelector(
  selectModelFormInputs,
  selectModelOutputData,
  (formInputs, outputs) => {
    const autofilled = reduceFormInputs('autofilled', formInputs);
    return mapObjIndexed((field, key) => {
      const { autofiller } = fieldsByName[key];
      return autofiller(outputs, formInputs);
    }, autofilled);
  },
);

export const makeAutofilledValueSelector = (fieldsByName) => () => createSelector(
  selectModelFormInputs,
  selectModelOutputData,
  selectName,
  (formInputs, outputs, field) => fieldsByName[field].autofiller(outputs, formInputs)
);

export const makePickedAutofilledValuesSelector = (fieldsByName) => () => createSelector(
  selectModelFormInputs,
  selectModelOutputData,
  selectNames,
  (formInputs, outputs, fields) => fields.reduce(
    (acc, field) => ({ ...acc, [field]: fieldsByName[field].autofiller(outputs, formInputs) }),
    {}
  )
);

export const selectCurrentRun = createSelector(
  modelsById,
  selectModelId,
  (byId, id) => get(`${id}.currentRun`, byId),
);

export const selectModelStatus = createSelector(
  selectCurrentRun,
  (run) => get('status', run),
);

export const selectRunOutputDataByReturnPeriod = createSelector(
  selectCurrentRun,
  (run) => getOr([], 'outputData.byReturnPeriod', run),
);

export const selectRunOutputDBE = createSelector(
  selectCurrentRun,
  (run) => getOr('', 'outputData.intermediate.dbe', run),
);

export const selectRunOutputMCE = createSelector(
  selectCurrentRun,
  (run) => getOr('', 'outputData.intermediate.mce', run),
);

export const selectRunOutputProbCollapseMce = createSelector(
  selectCurrentRun,
  (run) => getOr(null, 'outputData.intermediate.probCollapseMce', run),
);

export const selectRunOutputCollapseCapacityMedian = createSelector(
  selectCurrentRun,
  (run) => getOr(null, 'outputData.intermediate.collapseCapacityMedian', run),
);

const selectReturnPeriod = (_, props) => props.returnPeriod;

export const selectRunOutputDataForReturnPeriod = createSelector(
  selectRunOutputDataByReturnPeriod,
  selectReturnPeriod,
  (
    runOutputDataByReturnPeriod,
    returnPeriod
  ) => find((rp) => rp.returnPeriod === returnPeriod, runOutputDataByReturnPeriod),
);

const getModelTotalLossByReturnPeriod = map((item) => {
  const {
    returnPeriod,
    pga,
    saOverVUltDir1,
    saOverVUltDir2,
    totalLoss,
  } = item;

  return {
    returnPeriod,
    pga,
    saOverVUltDir1,
    saOverVUltDir2,
    ...totalLoss,
  };
});

export const selectTotalLossByReturnPeriod = createSelector(
  selectRunOutputDataByReturnPeriod,
  (data) => getModelTotalLossByReturnPeriod(data)
);

const getModelMeanLossBreakdownByReturnPeriod = map((item) => {
  const {
    returnPeriod,
    pga,
    meanLossBreakdown,
  } = item;

  return {
    returnPeriod,
    pga,
    ...meanLossBreakdown,
  };
});

export const selectMeanLossPercentBreakdownByReturnPeriod = createSelector(
  selectRunOutputDataByReturnPeriod,
  (data) => getModelMeanLossBreakdownByReturnPeriod(data)
);

export const selectMeanLossBreakdownPercentFor475ReturnPeriod = createSelector(
  selectMeanLossPercentBreakdownByReturnPeriod,
  (data) => find((x) => x.returnPeriod === 475, data),
);

const orderMeanLossColumns = (item) => pipe(
  get('meanLossBreakdown'),
  (meanLossBreakdown) => {
    const keyNames = keys(meanLossBreakdown);
    return keyNames.reduce((acc, name) => [
      ...acc,
      { name, value: meanLossBreakdown[name] },
    ], []);
  },
  orderBy(['value'], ['desc']),
  map((x) => x.name)
)(item);

const getModelLastIntensityMeanLossBreakdownColumns = (item) => pipe(
  last,
  orderMeanLossColumns
)(item);

export const selectMeanLossPercentBreakdownColumnsOrderedArray = createSelector(
  selectRunOutputDataByReturnPeriod,
  (data) => getModelLastIntensityMeanLossBreakdownColumns(data),
);

const getModelMeanLossBreakdownColumnsFor475ReturnPeriod = (item) => pipe(
  find((x) => x.returnPeriod === 475),
  orderMeanLossColumns
)(item);

export const selectMeanLossPercentBreakdownColumnsOrderedFor475ReturnPeriod = createSelector(
  selectRunOutputDataByReturnPeriod,
  (data) => getModelMeanLossBreakdownColumnsFor475ReturnPeriod(data)
);

export const selectAbsoluteAnnualLoss = createSelector(
  selectCurrentRun,
  (data) => getOr({}, 'outputData.absoluteAnnualLoss', data)
);

export const selectModelType = createSelector(
  modelsById,
  selectModelId,
  (byId, id) => get(`${id}.type`, byId)
);

export const selectPercentAnnualLoss = createSelector(
  selectCurrentRun,
  (data) => getOr({}, 'outputData.percentAnnualLoss', data),
);

const selectComponentGroupIndex = (_, props) => props.index;
export const makeComponentCalculatedCapacitiesSelector = () => createSelector(
  selectCurrentRun,
  selectComponentUuid,
  selectComponentGroupIndex,
  (run, componentUuid, index) => pipe(
    getOr([], 'outputData.intermediate.componentPopulation'),
    find((component) => component.componentUuid === componentUuid),
    getOr([], `performanceGroups[${index}].capacities`)
  )(run),
);

const getModelRepairTimeByReturnPeriod = (pLevel) => map((item) => {
  const {
    returnPeriod,
    pga,
    percentileRepairTime,
  } = item;

  return {
    returnPeriod,
    pga,
    ...percentileRepairTime[pLevel],
  };
});

export const selectRunIsATC138 = createSelector(
  selectCurrentRun,
  (data) => get('outputData.intermediate.isATC138', data),
);

export const selectRunRepairTimeMethod = createSelector(
  selectCurrentRun,
  (data) => get('outputData.intermediate.repairTimeMethod', data),
);

export const selectRunRegionCostMultiplier = createSelector(
  selectCurrentRun,
  (data) => get('outputData.intermediate.regionCostMultiplier', data),
);

export const selectRunDateCostMultiplier = createSelector(
  selectCurrentRun,
  (data) => get('outputData.intermediate.dateCostMultiplier', data),
);

export const selectRunOccupancyCostMultiplier = createSelector(
  selectCurrentRun,
  (data) => get('outputData.intermediate.occupancyCostMultiplier', data),
);

export const selectRunBuildingValueModifier = createSelector(
  selectCurrentRun,
  (data) => get('outputData.intermediate.buildingValueModifier', data),
);

export const selectRunExposureBreakdown = createSelector(
  selectCurrentRun,
  (data) => get('outputData.exposureBreakdown', data),
);

export const selectRunExposureBreakdownColumnsOrdered = createSelector(
  selectRunExposureBreakdown,
  (data) => pipe(
    values,
    filter((cg) => cg.componentGroup !== 'total'),
    orderBy(['maxRepairPotential'], ['desc']),
    map((x) => get('componentGroup', x)),
  )(data),
);

export const selectRunHasExposureBreakdown = createSelector(
  selectRunExposureBreakdownColumnsOrdered,
  (data) => !isEmpty(data),
);

export const selectRepairTimeByReturnPeriod = createSelector(
  selectRunOutputDataByReturnPeriod,
  selectPercentile,
  (data, percentile) => getModelRepairTimeByReturnPeriod(percentile)(data),
);

export const selectHasUploadsInProgress = createSelector(
  modelsById,
  selectModelId,
  (byId, id) => get(`${id}.hasUploadsInProgress`, byId)
);

export const selectGroundMotion = createSelector(
  modelById,
  (model) => getOr(null, 'groundMotion', model),
);

export const selectGroundMotionData = createSelector(
  selectGroundMotion,
  (groundMotion) => get('data', groundMotion) || [],
);

export const selectHasGroundMotionData = createSelector(
  selectGroundMotionData,
  (groundMotionData) => !!groundMotionData.length,
);

export const selectUploadedGroundMotionData = createSelector(
  modelById,
  (model) => get('uploadedGroundMotion.data', model) || [],
);

export const selectUploadedGroundMotionErrors = createSelector(
  modelById,
  (model) => getOr(null, 'uploadedGroundMotion.errors', model),
);

export const selectUploadedGroundMotionReturnPeriods = createSelector(
  selectUploadedGroundMotionData,
  (uploadedGroundMotionData) => getUniqueReturnPeriods(uploadedGroundMotionData),
);

export const selectGroundMotionDataByReturnPeriod = createSelector(
  selectGroundMotionData,
  (groundMotion) => {
    const periods = getUniquePeriods(groundMotion);
    const returnPeriods = getUniqueReturnPeriods(groundMotion);

    const groundMotionData = groundMotion.reduce((acc, curr) => {
      const res = [...acc];
      const rpIndex = returnPeriods.indexOf(curr.returnPeriod);
      const saByPeriod = getOr({}, `${rpIndex}.saByPeriod`, res);
      res[rpIndex] = {
        returnPeriod: curr.returnPeriod,
        saByPeriod: {
          ...saByPeriod,
          [curr.period]: curr.sa,
        },
      };
      return res;
    }, []);
    return {
      sa: groundMotionData,
      periods,
    };
  },
);

export const selectIsGroundMotionMatch = createSelector(
  selectGroundMotion,
  selectMethod,
  selectUpload,
  (groundMotion, method, uuid) => {
    const modelGroundMotionMethod = get('method', groundMotion);
    const modelGroundMotionUuid = get('uuid', groundMotion);
    if (method === 'upload') {
      return modelGroundMotionMethod === method
        && modelGroundMotionUuid === uuid;
    }
    return modelGroundMotionMethod === method;
  },
);

export const selectModeShape = createSelector(
  modelById,
  (model) => getOr(null, 'modeShape', model),
);

export const selectIsModeShapeMatch = createSelector(
  selectModeShape,
  selectMethod,
  selectUpload,
  (modeShape, method, uuid) => {
    const modelModeShapeMethod = get('method', modeShape);
    const modelModeShapeUuid = get('uuid', modeShape);
    if (method === 'upload') {
      return modelModeShapeMethod === method
        && modelModeShapeUuid === uuid;
    }
    return modelModeShapeMethod === method;
  },
);

export const selectModeShapeIsProcessing = createSelector(
  selectModeShape,
  (modeShape) => {
    const status = getOr(null, 'status', modeShape);
    if (status === null) {
      return null;
    }
    return !isTerminal(status);
  }
);

export const selectModeShapeData = createSelector(
  selectModeShape,
  (modeShape) => get('data', modeShape) || [],
);

export const selectUploadedModeShapeErrors = createSelector(
  modelById,
  (model) => getOr(null, 'uploadedModeShape.errors', model),
);

export const selectHasModeShapeData = createSelector(
  selectModeShapeData,
  (modeShapeData) => !!modeShapeData.length,
);

export const selectModeShapeDataForDirection = createSelector(
  selectDirection,
  selectModeShapeData,
  (direction, modeShapeData) => pipe(
    filter((row) => row.direction === direction),
    orderBy(['mode', 'floor'], ['asc', 'asc'])
  )(modeShapeData),
);

export const selectMaxModeForDirection = createSelector(
  selectModeShapeDataForDirection,
  (modeShapeData) => pipe(
    map((x) => x.mode),
    max,
  )(modeShapeData),
);

export const selectMaxFloorForDirection = createSelector(
  selectModeShapeDataForDirection,
  (modeShapeData) => pipe(
    map((x) => x.floor),
    max,
  )(modeShapeData),
);

export const selectModeShapeFloors = createSelector(
  selectModeShapeDataForDirection,
  (modeShapeData) => pipe(
    map((x) => x.floor),
    uniq,
  )(modeShapeData),
);

export const selectModeShapeModes = createSelector(
  selectModeShapeDataForDirection,
  (modeShapeData) => pipe(
    map((x) => x.mode),
    uniq,
  )(modeShapeData),
);

export const selectMaxModeValueForDirection = createSelector(
  selectModeShapeDataForDirection,
  (modeShapeData) => pipe(
    map((x) => Math.abs(x.value)),
    max,
  )(modeShapeData),
);

export const selectModeShapeForDirGroupByFloorAndMode = createSelector(
  selectModeShapeDataForDirection,
  (modeShapeData) => reduce((acc, curr) => ({
    ...acc,
    [`${curr.floor}|${curr.mode}`]: curr.value,
  }), {}, modeShapeData),
);

export const selectModeShapeForDirGroupByMode = createSelector(
  selectModeShapeDataForDirection,
  (modeShapeData) => groupBy('mode', modeShapeData),
);

export const selectStructuralResponseById = createSelector(
  selectResponseId,
  modelById,
  (responseId, model) => {
    if (!responseId || !model || !model.structuralResponses) {
      return null;
    }
    const response = model.structuralResponses.find((r) => (r.uuid === responseId));
    return response || null;
  }
);

export const selectStructuralResponseByEdpKey = createSelector(
  selectEdpKey,
  modelById,
  (edpKey, model) => {
    if (!edpKey || !model || !model.structuralResponses) {
      return null;
    }
    const response = model.structuralResponses.find((r) => (r.edp.key === edpKey));
    return response || null;
  }
);

export const selectIsStructuralResponseMethodMatch = createSelector(
  selectMethod,
  selectStructuralResponseByEdpKey,
  (method, response) => {
    if (!response || !method) {
      return null;
    }
    return get('method', response) === method;
  }
);

const getData = getOr(null, 'data');

export const selectStructuralResponseDataById = createSelector(
  selectStructuralResponseById,
  getData
);

export const selectStructuralResponseDataByEdpKey = createSelector(
  selectStructuralResponseByEdpKey,
  getData
);

export const selectStructuralResponseErrors = createSelector(
  selectStructuralResponseById,
  (response) => (response ? response.errors : null)
);

export const selectStructuralResponseIsStory = createSelector(
  selectStructuralResponseById,
  (response) => getOr(null, 'edp.isStory', response)
);

export const selectStructuralResponseStatusById = createSelector(
  selectStructuralResponseById,
  (response) => getOr(null, 'status', response)
);

export const selectStructuralResponseStatusByEdpKey = createSelector(
  selectStructuralResponseByEdpKey,
  (response) => getOr(null, 'status', response)
);

const isSuccess = (status) => status === SUCCESS || null;

export const selectStructuralResponseIsSuccessById = createSelector(
  selectStructuralResponseStatusById,
  isSuccess
);

export const selectStructuralResponseIsSuccessByEdpKey = createSelector(
  selectStructuralResponseStatusByEdpKey,
  isSuccess
);

export const selectStructuralResponseIsProcessing = createSelector(
  selectStructuralResponseStatusById,
  (status) => (status === null ? null : !isTerminal(status))
);

export const selectGroundMotionIsProcessing = createSelector(
  selectGroundMotion,
  (groundMotion) => {
    const status = getOr(null, 'status', groundMotion);
    if (status === null) {
      return null;
    }
    return !isTerminal(status);
  }
);

const dedupeAndOrderNumericalArray = pipe(
  sort((a, b) => a - b),
  sortedUniq,
);

const getReturnPeriodsFromData = (data) => {
  const dataDir1 = get('dir1', data);
  if (Array.isArray(dataDir1)) {
    return dedupeAndOrderNumericalArray(dataDir1.map((item) => item.returnPeriod));
  }
  return null;
};

export const selectStructuralResponseReturnPeriodsByEdpKey = createSelector(
  selectStructuralResponseDataByEdpKey,
  getReturnPeriodsFromData,
);

export const selectStructuralResponseReturnPeriodsById = createSelector(
  selectStructuralResponseDataById,
  getReturnPeriodsFromData,
);

export const selectStructuralResponseLocations = createSelector(
  selectStructuralResponseById,
  (response) => {
    const dataDir1 = get('data.dir1', response);
    if (Array.isArray(dataDir1)) {
      const periods = dataDir1.map((item) => item.location);
      return dedupeAndOrderNumericalArray(periods);
    }
    return null;
  }
);

export const selectStructuralResponseDataForDirection = createSelector(
  selectDirection,
  selectStructuralResponseDataByEdpKey,
  (direction, data) => getOr(null, `dir${direction}`, data)
);

export const maxMedianCeilOfStructuralResponseDirection = createSelector(
  selectStructuralResponseDataForDirection,
  selectEdpKey,
  (response, edpKey) => {
    const { isPercent } = edpsByKey[edpKey];
    return ceilCoordinateValue('median', isPercent ? 4 : 2)(response);
  }
);

export const maxLocationOfStructuralResponseDirection = createSelector(
  selectStructuralResponseDataForDirection,
  (response) => ceilCoordinateValue('location', 0)(response),
);

export const selectStructuralResponseDataForDirectionWithLocationLabels = createSelector(
  selectEdpKey,
  selectStructuralResponseDataForDirection,
  (edpKey, data) => {
    if (data) {
      const roof = maxBy('location', data);
      return map((item) => ({
        ...item,
        locationLabel: getLocationLabel(item.location, edpsByKey[edpKey].locationType, roof),
      }), data);
    }
    return [];
  },
);

export
const selectStructuralResponseDataForDirectionWithLocationLabelsByReturnPeriod = createSelector(
  selectStructuralResponseDataForDirectionWithLocationLabels,
  (response) => groupBy('returnPeriod', response),
);

export const selectStructuralResponseDataForDirectionWithLocationLabelsByLocation = createSelector(
  selectStructuralResponseDataForDirectionWithLocationLabels,
  (response) => {
    const responsesByLocation = groupBy((d) => d.location, response);
    return pipe(
      uniqBy((r) => r.location),
      orderBy('location', 'desc'),
      map(({ location, locationLabel }) => ({
        location,
        locationLabel,
        responses: responsesByLocation[location],
      })),
    )(response);
  },
);
